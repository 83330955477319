@font-face {
    font-family: 'FbAbsoluti';
    font-weight: 400;
    /*Can be any text*/
    src: url("./FbAbsoluti-Regular.woff2") format("woff2"), url("./FbAbsoluti-Regular.woff") format("woff");
}

@font-face {
    font-family: 'FbAbsoluti';
    font-weight: 700;
    /*Can be any text*/
    src: url("./FbAbsoluti-Bold.woff2") format("woff2"), url("./FbAbsoluti-Bold.woff") format("woff");
}

* {
    font-family: 'FbAbsoluti';
}